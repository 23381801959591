import Alpine from 'alpinejs/dist/module.cjs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus/dist/module.cjs'
import persist from '@alpinejs/persist'
import simulation from './simulation'

window.Alpine = Alpine

Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(persist)

Alpine.data('simulation', simulation)

document.addEventListener('DOMContentLoaded', () => {
  Alpine.start()
})
